<template>
  <div class="content">
    <work-in-progress/>

    <div class="grid-x align-justify">
      <div class="cell shrink">
        <p>Administradores</p>
        <div class="search-container">
          <input
            type="text"
            v-model="searchAdmin"
            @keyup.enter="submitAdmin"
            :placeholder="$t('templates.search')"
          >
        </div>
      </div>
      <div class="cell shrink">
        <!-- <button
          class="button md fill primary"
          @click="toAssignUsers"
        >
          Asignar usuarios
        </button> -->
      </div>
    </div>

    <data-table-admins
      :headers="headers"
      :items="admins"
      :paginationOptions="paginationOptionsAdmin"
      :loader="$apollo.queries.adminsByCampaign.loading"
      v-on:change-page="changePageAdmin"
    />

    <div class="grid-x">
      <div class="cell">
        <p>Agentes</p>
        <div class="search-container">
          <input
            type="text"
            v-model="searchAgent"
            @keyup.enter="submitAgent"
            :placeholder="$t('templates.search')"
          >
        </div>
      </div>
    </div>

    <data-table-agents
      :headers="headers"
      :items="agents"
      :paginationOptions="paginationOptionsAgent"
      :loader="$apollo.queries.agentsByCampaign.loading"
      v-on:change-page="changePageAgent"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import UsersByCampaignGql from '@/graphql/queries/users/usersByCampaign.gql';
import DataTableAdmins from '@/components/settings/DataTableAdmins.vue';
import DataTableAgents from '@/components/settings/DataTableAgents.vue';
import { getQuery } from '@/utils/gql-api';

export default {
  components: {
    DataTableAdmins,
    DataTableAgents,
    WorkInProgress: () => import('@/components/WorkInProgress.vue'),
  },
  apollo: {
    adminsByCampaign() {
      return {
        query: gql`${UsersByCampaignGql}`,
        variables() {
          return {
            role: 'admin',
            id: this.campaignId,
            length: this.paginationOptionsAdmin.perPage,
            all: false,
            search: null,
            page: 1,
          };
        },
        update: (data) => {
          if (data.usersByCampaign && data.usersByCampaign.total) {
            this.paginationOptionsAdmin.pageTotal = data.usersByCampaign.total;
          }
          return data.usersByCampaign;
        },
      };
    },
    agentsByCampaign() {
      return {
        query: gql`${UsersByCampaignGql}`,
        variables() {
          return {
            role: 'agent',
            id: this.campaignId,
            length: this.paginationOptionsAgent.perPage,
            all: false,
            search: null,
            page: 1,
          };
        },
        update: (data) => {
          if (data.usersByCampaign && data.usersByCampaign.total) {
            this.paginationOptionsAgent.pageTotal = data.usersByCampaign.total;
          }
          return data.usersByCampaign;
        },
      };
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'USUARIO',
          value: 'user',
        },
        {
          text: 'CORREO',
          value: 'email',
        },
        {
          text: 'PERFIL',
          value: 'profile',
        },
        {
          text: 'CANALES',
          value: 'channels',
        },
      ],
      paginationOptionsAdmin: {
        pageTotal: 0,
        page: 1,
        perPage: 10,
      },
      paginationOptionsAgent: {
        pageTotal: 0,
        page: 1,
        perPage: 10,
      },
      searchAdmin: null,
      searchAgent: null,
      adminsByCampaign: [],
      agentsByCampaign: [],
    };
  },
  computed: {
    ...mapState({
      campaign: state => state.setting.currentCampaign,
    }),
    campaignId() {
      return this.campaign.id;
    },
    admins() {
      if (this.adminsByCampaign && this.adminsByCampaign.data) {
        return this.adminsByCampaign.data;
      }
      return [];
    },
    agents() {
      if (this.agentsByCampaign && this.agentsByCampaign.data) {
        return this.agentsByCampaign.data.map((agent) => {
          const data = { ...agent };
          data.channels = [];
          agent.campaigns.forEach((campaign) => {
            data.channels = [...data.channels, ...campaign.channels];
          });
          return data;
        });
      }
      return [];
    },
  },
  methods: {
    toAssignUsers() {
      this.$router.push(`/campaigns/${this.campaignId}/assign-users`);
    },
    changePageAdmin(page) {
      if (this.paginationOptionsAdmin.page !== page) {
        this.paginationOptionsAdmin.page = page;
        this.fetchMoreAdmin(this.searchAdmin, this.paginationOptionsAdmin.page);
      }
    },
    changePageAgent(page) {
      if (this.paginationOptionsAgent.page !== page) {
        this.paginationOptionsAgent.page = page;
        this.fetchMoreAgent(this.searchAgent, this.paginationOptionsAgent.page);
      }
    },
    async fetchMoreAdmin(search, page) {
      this.adminsByCampaign = await getQuery(UsersByCampaignGql, {
        role: 'admin',
        id: this.campaignId,
        length: this.paginationOptionsAgent.perPage,
        all: false,
        search,
        page,
      }, 'usersByCampaign');
    },
    async fetchMoreAgent(search, page) {
      this.agentsByCampaign = await getQuery(UsersByCampaignGql, {
        role: 'agent',
        id: this.campaignId,
        length: this.paginationOptionsAgent.perPage,
        all: false,
        search,
        page,
      }, 'usersByCampaign');
    },
    submitAdmin() {
      this.fetchMoreAdmin(this.searchAdmin, 1);
    },
    submitAgent() {
      this.fetchMoreAgent(this.searchAgent, 1);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_users-tab.scss';
</style>
