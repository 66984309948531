<template>
  <div>
    <table-loader v-if="loader" />
    <table v-else class="container-table hover">
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="header"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.id"
          class="row-table"
        >
          <td>{{ item.name }}</td>
          <td class="table-name">{{ item.email }}</td>
          <td>Agente</td>
          <td>
            <channel-badge
              v-for="(channel, index) in item.channels"
              :key="index"
              :type="channel.channelType.key"
              class="badge"/>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      v-show="!isDataValid"
      :items="items"
      :total-pages="paginationOptions.pageTotal"
      :page-size="paginationOptions.perPage"
      @changePage="onChangePage"
    />
    <div v-if="isDataValid" class="grid-x no-data-text">
      <table>
        <tbody>
          <tr class="row-table">
            <td colspan="5" class="align-justify">
              {{ $t('templates.table.nodata') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import TableLoader from '@/components/loaders/Table.vue';
import Pagination from '@/components/Pagination.vue';
import ChannelBadge from '@/components/ChannelBadge.vue';

export default {
  components: {
    TableLoader,
    Pagination,
    ChannelBadge,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    check: {
      type: Boolean,
      required: false,
      default: false,
    },
    loader: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationOptions: {
      type: Object,
      required: false,
      default: () => ({
        pageTotal: 1,
        page: 1,
        perPage: 25,
      }),
    },
    deleteCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showLoading: true,
      pages: [],
      checkAll: [],
      selected: [],
    };
  },
  computed: {
    ...mapState({
      agentsAssignList: state => state.setting.agentsAssignList,
    }),
    isDataValid() {
      return !this.loader && this.items && this.items.length === 0;
    },
  },
  watch: {
    selected(newValue) {
      this.setAgentsAssignList(newValue);
    },
    deleteCheck(newValue) {
      if (newValue) {
        this.selected = [];
        this.checkAll = [];
      }
    },
  },
  methods: {
    ...mapMutations({
      setAgentsAssignList: 'setting/setAgentsAssignList',
      deleteAssignUser: 'setting/deleteAssignUser',
    }),
    onChangePage(page) {
      if (page !== this.paginationOptions.page) {
        this.$emit('change-page', page);
      }
    },
    addSelected(item) {
      const index = this.selected.findIndex(select => select.id === item.id);
      if (index === -1) {
        this.selected = [...this.selected, item];
      }
    },
    triggerSelectedAll(e) {
      this.items.forEach((item) => {
        if (e.target.checked) {
          this.addSelected(item);
        } else {
          const index = this.selected.findIndex(agent => agent.id === item.id);
          if (index !== -1) {
            this.selected.splice(index, 1);
          }
          this.deleteAssignUser(item.id);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_data-table-assign-users.scss';
</style>
