import { render, staticRenderFns } from "./DataTableAgents.vue?vue&type=template&id=614af292&scoped=true&"
import script from "./DataTableAgents.vue?vue&type=script&lang=js&"
export * from "./DataTableAgents.vue?vue&type=script&lang=js&"
import style0 from "./DataTableAgents.vue?vue&type=style&index=0&id=614af292&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614af292",
  null
  
)

export default component.exports